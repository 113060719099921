const owlCarousel = require('./plugins/owlSlider.js');
const lazyload = require('./plugins/lazyload.js');
const fancybox = require('./plugins/fancybox.js');
const TweenMax = require('./plugins/TweenMax.min.js');
const inview = require('./plugins/inView.js');
const imagesLoaded = require('./plugins/imagesLoaded.js');
const masonry = require('./plugins/masonry.pkgd.min.js');

window.jQuery = jQuery;
window.$ = jQuery;

/* Author: herrlich media */
function _dataStore(){

    /* scrolling ++++++++++++++++++++++++++++++++++++++++++*/

    this.docScroll = '';
    this.win = $(window);
    this.body = $('body');
    /* window ++++++++++++++++++++++++++++++++++++++++++*/

    this.docWidth  = $(document).width();
    this.docHeight = $(document).height();
    this.winHeight = $(window).height();

    /* non editable ++++++++++++++++++++++++++++++++++++++++++*/
    this.endOfResize = false;

    /* browser ++++++++++++++++++++++++++++++++++++++++++*/

    this.isIe = false;
    this.isFirefox = false;
    this.isSafari = false;
    this.isNoIe = false;
    this.touch = false;
    this.touchBreakPoint = 768

    /* ids ++++++++++++++++++++++++++++++++++++++++++*/

    this.globalIds  = JSON.parse(wp_urls.globalIds);

    /* fancybox ++++++++++++++++++++++++++++++++++++++++++*/
    this.fancyBoxOnceOpened = false;

    /* smoothscrolling ++++++++++++++++++++++++++++++++++++++++++*/
    this.scrollEventListener = 'wheel';
    this.smoothDisabled = false;

    /* masonry ++++++++++++++++++++++++++++++++++++++++++*/
    this.grid = $('.infinityWrap');

    /* Map ++++++++++++++++++++++++++++++++++++++++++*/
    this.mapMarkers = [];
    this.map = '';

    /* Parapllax ++++++++++++++++++++++++++++++++++++++++++*/
    this.parallaxWrap = $('[data-parallaxWrap]');
    this.parallaxImg = this.parallaxWrap.length;
    this.parallaxObject = [];
    this.allowScroll = true;
}

jQuery(function($) {

    $(document).ready(function() {

        /*++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++*/
        /* Ready Inits +++++++++++++++++++++++++++++++++++++++++++++++++++++++++*/
        /* +++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++*/

    	dataStore = new _dataStore();

    	/* browser/touch detection ++++++++++++++++++++++++++++++++++++++++++*/
        browserDetection();

        // Document Ready Class
        dataStore.body.addClass('docReady');

        /* Cookie Popup +++++++++++++++++++++++++++++++++++++++++++++++++++++*/
        cookiePopup();

        /* Herrlich Media Nav Init ++++++++++++++++++++++++++++++++++++++++++*/
        hmNavInit();

        /* NAV mobile submenu ++++++++++++++++++++++++++++++++++++++++++++++*/
        mobileSubmenu();

       /*--- Scroll to ID on Click ---*/
        $('a[href*="#"]:not([href="#"])').click(function() {
            if (location.pathname.replace(/^\//,'') == this.pathname.replace(/^\//,'') && location.hostname == this.hostname) {
              var target = $(this.hash);
              target = target.length ? target : $('[name=' + this.hash.slice(1) +']');
              if (target.length) {
                $('.open').removeClass('open');
                $('html,body').stop().animate({
                  scrollTop: target.offset().top -80
                }, 600);
                return false;
              }
            }
        });

        /* FAQs +++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++*/
        if ($('#faqs').length) {
            $('.fag__headline').on('click touch', (e) => {
                const faqEl = $(e.currentTarget).parent();

                faqEl.toggleClass('active');
                faqEl.find('.faq__content').slideToggle('swing');
            });
        }


        /*++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++*/
        /* Plugins +++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++*/
        /* +++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++*/

        /* lazy load  ++++++++++++++++++++++++++++++++++++++++++*/
        $(".lazy").lazyload({
            threshold : 1000,
            load : function(i,e){
                $(this).removeClass('lazy');
            }
        });

        $('.lazy').load(function() {
            masonry_update();
        });

       // FANCYBOX
        $('[data-fancybox]').fancybox({
            afterLoad: function(){
                dataStore.smoothDisabled = true;
            },
            beforeClose: function(){
              dataStore.smoothDisabled = false;
            }
        });

        /**** Map Box ***/
        if($('#map').length){
            mapBox();
        }

        /*--- IN VIEW ---*/
        $('.fadeIn').one('inview', function (event, visible) {
            if (visible === true) {
                $(this).addClass('vis');
            }
        });


        $('.fadeInRow').one('inview', function (event, visible) {
            if (visible === true) {
                $(this).find('> div').each(function(i, el){
                    setTimeout(function(){
                        $(el).addClass('vis');
                    }, 150 * i);
                });
                //$(this).find('.owl-item .item').not('.active').addClass('vis');
            }
        });


        /*++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++*/
        /* Scroll ++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++*/
        /* +++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++*/

        $(window).scroll(function() {

        	dataStore.docScroll = $(window).scrollTop();

            // Parallax Scroll
            parallaxScroll();


        });


    }); /* ready end ++++++++++++++++++++++++++++++++++++++++++*/


    $(window).resize(function() {
        dataStore.docWidth  = $(document).width();
        dataStore.docHeight = $(document).height();
        dataStore.winHeight = $(window).height();

        $(window).trigger('scroll');

        clearTimeout(dataStore.endOfResize);
        dataStore.endOfResize = setTimeout(function(){
            afterResize();
        }, 250);

    });

    function afterResize(){
        mobileSubmenu();

        // Parallax Object
        parallaxObject();
        if(dataStore.parallaxImg && window.innerWidth <= 985){
            for(var a = 0, aLen = dataStore.parallaxObject.length; a < aLen; a++) {
                dataStore.parallaxObject[a].el.css({
                    'transform' : 'translate3d(0,0,0)',
                    '-webkit-transform' : 'translate3d(0,0,0)'
                });
            }
        }
    }


    $(window).load(function() {

        // Check for Has in URl
        if(window.location.hash) {
  
          var target = $(window.location.hash);
          console.log(target.length);
          $('html,body').animate({
            scrollTop: target.offset().top - 40
          }, 300);
        }

        /*++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++*/
        /* Plugins +++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++*/
        /* +++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++*/

        /**** OWL SLIDER ***/
        var owlCarousel = $('.owl-carousel');
        owlCarousel.owlCarousel({
            loop:true,
            margin:0,
            items: 1,
            nav:true,
            touchDrag  : true,
            mouseDrag  : true,
            navText: '',
            smartSpeed: 700
        });


        /* map ++++++++++++++++++++++++++++++++++++++++++*/
        // $('.map').each(function(){
        //     render_map($(this));
        // });


        /*------------------------ infinity -----------------------*/
        if($('.infinityWrap').length && $('.next').length){
            // var msnry = dataStore.grid.data('masonry');
            dataStore.grid.infiniteScroll({
                path: '.next',
                append: '.item',
                status: '.scroller-status',
                hideNav: '.pagination',
                history: false
                // outlayer: msnry,
            });

            dataStore.grid.on( 'append.infiniteScroll', function( event, response, path ) {
                $('.infinityWrap > div').not('.vis').each(function(i, el){
                    setTimeout(function(){
                        $(el).addClass('vis');
                    }, 250 * i);
                });
            });
        }

        // Parallax Object Creare on load
        parallaxObject();

    });



});


function navDropdown(){
    var config = {
        sensitivity: 3, // number = sensitivity threshold (must be 1 or higher)
        interval: 0,  // number = milliseconds for onMouseOver polling interval
        over: doOpen,   // function = onMouseOver callback (REQUIRED)
        timeout: 350,   // number = milliseconds delay before onMouseOut
        out: doClose    // function = onMouseOut callback (REQUIRED)
    };


    function doOpen() {
        if ($(this).hasClass("menu-item-has-children")) {
            $(this).addClass("hover");
            $('ul:first',this).addClass('vis');
        }
    }

    function doClose() {
        if ($(this).hasClass("menu-item-has-children")) {
            $(this).removeClass("hover");
            $('ul:first',this).removeClass('vis');
        }
    }

    $("#mainnavCon ul li").hoverIntent(config);

}

 /* HM Nav  Init ++++++++++++++++++++++++++++++++++++++++++*/
function hmNavInit(){
    /* Mobile NAV ++++++++++++++++++++++++++++++++++++++++++*/
    $('#navToggle').click(function() {
        var nav = $('#mainnavCon');
        $('#navToggle, #mainnavCon, body').toggleClass('open');
        if(nav.hasClass('open')) {
            setTimeout(function() {
                nav.addClass('overflowScroll');
            },300);
        } else {
            nav.removeClass('overflowScroll');
        }
    });

    /* NAV more ++++++++++++++++++++++++++++++++++++++++++*/
     // $('.navWrap').herrlichesMenu({
     //    container     : '.navWrap', //Container der die li´s enthaelt
     //    text      : 'Mehr', // Text des Mehr Buttons
     //    entrySelector   : 'li', // selector fuer die einzelnen Menupunkte
     //    stopCheckPoint  : dataStore.touchBreakPoint, // Bei window-weite unter dieser Zahl check deaktivieren (Responsive mobile Point)
     //    addToEntry    : 25,   // wenn zu spaet ins more geschoben wird, erhoehen und andersrum
     //    elementPosition   : 'relative',
     //    moreClasses   : 'menu-item-has-children menu-item', // klassen die der Morebutton zusaetzlich bekommen soll
     //    whileResize   : function(){ // zusaetzliche Aufgaben beim Resize

     //    },
     //    afterResize   : function(){ // Aufgaben die nach dem Resize ausgefuehrt werden sollen
     //        // Init nav DropDown
     //        navDropdown();
     //    }
     // });

    // zIndex Menu
    if($('ul.sub-menu').length){
        var zIndex = 1;
        $('#header-menu li.menu-item').mouseover(function() {
            $(this).css('z-index', zIndex);
            zIndex++;
        });
    }
}


 /* Mobile Submenu  ++++++++++++++++++++++++++++++++++++++++++*/
function mobileSubmenu(){
    if(dataStore.touch === true || dataStore.docWidth < dataStore.touchBreakPoint){
        var mobileSubmenuFlag = true;
        $('.menuPlus').off('touchStart click').on('touchStart click', function(){
            if(mobileSubmenuFlag === true){
                mobileSubmenuFlag = false;
                $(this).toggleClass('active');
                $(this).siblings('.sub-menu').slideToggle(250, "swing", function(){
                    mobileSubmenuFlag = true;
                });
            }
        });
    }
}


 /* Masonry Wrap  ++++++++++++++++++++++++++++++++++++++++++*/
function masonry_update() {
    dataStore.grid = $('.masonryWrap');
    dataStore.grid.imagesLoaded(function(){
       dataStore.grid.masonry({
            itemSelector: '.item',
            columnWidth: '.item',
            percentPosition: true,
            isOriginLeft: true
        });
    }).progress( function() {
        dataStore.grid.find('> .item').each(function(index) {
            (function(that, i) {
                var t = setTimeout(function() {
                    $(that).addClass('vis');
                }, 150 * i);
            })(this, index);
        });
    });
}


 /* Browser Detection  ++++++++++++++++++++++++++++++++++++++++++*/
function browserDetection() {
    var browser_ = navigator.userAgent.toLowerCase();

    if(browser_.indexOf('msie') > -1 || !!browser_.match(/trident.*rv\:11\./)) {
        dataStore.isIe  = true;
        $('body').addClass('ie');

    } else if(browser_.indexOf('edge') > -1) {
        dataStore.isIe  = true;
        $('body').addClass('ie');

    } else if(browser_.indexOf('firefox') > -1) {
        dataStore.isFirefox = true;
        dataStore.isNoIe = true;
        $('body').addClass('firefox no-ie');
    } else if(browser_.indexOf('chrome') > -1) {
        dataStore.isNoIe = true;
        $('body').addClass('chrome no-ie');
    } else if(browser_.indexOf('safari') > -1) {
        dataStore.isSafari = true;
        dataStore.isNoIe = true;
        $('body').addClass('safari no-ie');
    } else{
        dataStore.isNoIe = true;
        $('body').addClass('no-ie');
    }

    /* touch check ++++++++++++++++++++++++++++++++++++++++++*/
    if($('.touchevents').length){
        dataStore.touch = true;
    }
}


 /* Cookie Popup  ++++++++++++++++++++++++++++++++++++++++++*/
function cookiePopup(){

    /*--- Cookie Popup  ---*/
    var cookieClosed = localStorage.getItem('cookieClosed');
    if (cookieClosed === null){
        $('.euCookiePopupContainer').addClass('show');
    }
    $('.euCookiePopupAccept').on('click', function(){
        $('.euCookiePopupContainer').removeClass('show');
        localStorage.setItem('cookieClosed',true);
    });
}


// Parallax Obj
function parallaxObject(){
    //  Parallax Scroll Img  ++++++++++++++++++++++++++++++++++++++++++*/
     if (dataStore.docWidth > 986 && dataStore.parallaxImg){

        dataStore.parallaxObject =[];

        dataStore.parallaxWrap.each(function(i,el){
            var elHeight = $(el).height(),
                topEl = $(el).offset().top,
                speed = $(el).data('speed');

            if(topEl < dataStore.winHeight){
                topEl = dataStore.winHeight;
            }

            if(speed === 'random'){
                var min = 60;
                var max = 100;
                var random = Math.random() * (+max - +min) + +min;
                speed = random;
            }

            if($(el).data('direction') === 'down'){
                var diretionChanged = true;
            }
            else {
                var diretionChanged = false;
            }
            var tempObject = {
                'el':$(el),
                'elHeight':elHeight,
                'maxTransPix':speed,
                'topEl':topEl,
                'direction': diretionChanged
            }
            dataStore.parallaxObject.push(tempObject);
        });

    }
}


// Parallax Scroll Function
function parallaxScroll(){
    if(dataStore.docWidth > 986 && dataStore.parallaxImg){
        for(var a = 0, aLen = dataStore.parallaxObject.length; a < aLen; a++) {

            transPerPix  = dataStore.parallaxObject[a].maxTransPix / (dataStore.winHeight + dataStore.parallaxObject[a].elHeight); //Faktor je nach Höhe von Bildschirm und IMg im verhältnis.
            currentScrollAtWindowBottom = dataStore.docScroll + dataStore.winHeight;
            translate = (currentScrollAtWindowBottom - dataStore.parallaxObject[a].topEl) * transPerPix;

            if (translate > dataStore.parallaxObject[a].maxTransPix){
                translate = dataStore.parallaxObject[a].maxTransPix;
            }else if(translate < 0){
                translate = 0;
            }

            if (dataStore.parallaxObject[a].direction){
                TweenMax.to(dataStore.parallaxObject[a].el, 1.1, {
                    y:translate,
                    ease: Expo.easeOut,
                    overwrite: 5
                });
            } else {
                TweenMax.to(dataStore.parallaxObject[a].el, 1.1, {
                    y:-translate,
                    ease: Expo.easeOut,
                    overwrite: 5
                });
            }

        }
    } else {
        TweenMax.to(dataStore.parallaxWrap, 1.1, {
            y:0,
            ease: Expo.easeOut,
            overwrite: 5
        });
    }
}


// Smooth Mousewheel Scrolling
function smoothScroll(){
    if(!dataStore.isIe){
        document.onmousewheel = function(){
          customScroll();
        };
        preventWheelScroll();
    }
}

// Custom Scroll Function
function customScroll(event){
    if (dataStore.smoothDisabled){
      return;
    }
    var delta = 0;
    if (!event){
        event = window.event;
    }
    if (event.wheelDelta) {
        delta = event.wheelDelta/300;
    } else if(event.detail) {
        delta = -event.detail/3;
    }
    if (delta){
        var scrollTop = dataStore.win.scrollTop();
        var finScroll = scrollTop - (parseInt(delta * 100) * 3);
        TweenMax.to(dataStore.win, 1.1, {
            scrollTo : { y: finScroll, autoKill:true },
            ease: Expo.easeOut,
            autoKill: true,
            overwrite: 5
        });
    }
    preventWheelScroll();
    event.returnValue = false;
}

// Prevent Default Scrolling
function preventWheelScroll(){
    if(dataStore.isFirefox){
        dataStore.scrollEventListener = 'DOMMouseScroll';
    }
    if(document.addEventListener){
        document.addEventListener(dataStore.scrollEventListener, customScroll, {passive: false});
    }
}



